@import 'io-sanita-theme/theme/bootstrap-italia-base-config';
@import 'io-sanita-theme/theme/io-sanita/mixins';

.public-ui .card,
.card {
  &.card-ghost {
    background-color: transparent;
    .card-body {
      .card-title {
        font-weight: $font-weight-bold;
        @include rem-size(font-size, 32);

        &.card-title-icon {
          .card-title-link {
            display: flex;
            justify-content: space-between;

            .icon {
              flex: 0 0 auto;
              color: var(--is-accent);
              fill: var(--is-accent);
              margin-left: 1rem;
            }
          }
        }

        &:last-child {
          margin-top: 0;
        }
      }
    }
    &.border-bottom-card {
      &:before {
        height: 1px;
        background-color: $gray-border;
      }
    }
  }
}
