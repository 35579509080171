.public-ui .card.card-guide {
  border: 1px solid var(--primary-lighter);

  .card-body {
    display: flex;
    align-items: center;
    width: 100%;
    .card-guide-content {
      flex: 1 1 100%;
    }
    .icon {
      height: 17px !important;
      min-width: 21px;
    }

    .card-text {
      &,
      p {
        font-size: 1rem;
      }
    }
  }
}
