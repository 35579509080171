.public-ui {
  .block {
    margin-bottom: 1.5rem;
  }
  .block[class*='bg-']:has(+ .block[class*='bg-']) {
    margin-bottom: 0;
  }
}

.public-ui,
.cms-ui {
  .block {
    .is-block-description {
      &,
      p {
        @include rem-size(font-size, 24);
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}

// .is-block-description {
//   &,
//   p {
//     @include rem-size(font-size, 24);
//   }
//   p {
//     margin-bottom: 0;
//   }
// }
