.photogallery-item {
  .img-wrapper {
    position: relative;

    &.responsive {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
