.card.card-simple {
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-body-main {
      //wrapper per mantenere il contenuto della card all'inizio della card
    }
  }
}
