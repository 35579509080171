.complete-block-links-template {
  .card a {
    text-decoration: none;
  }

  .card-wrapper {
    .card {
      border: 1px solid var(--primary-lighter);
      border-bottom-width: 8px;

      h3.card-title.h5 {
        font-size: 1rem !important;
        line-height: 1.44444rem;
        color: var(--bs-link-color);

        .external-link {
          flex-shrink: 0;
        }
      }

      .image-container {
        display: flex;
        overflow: hidden;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        border-radius: 50%;
        margin: 24px 0px 0px 24px;
        background-color: #fff;

        .img-wrapper {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  a.item-card-link {
    height: 100%;

    > .external-link {
      display: none;
    }
  }
}
