@import 'io-sanita-theme/theme/io-sanita/mixins';

.card {
  &.rounded {
    overflow: hidden;
  }

  .card-title,
  .card-body .card-title {
    &,
    &h3,
    &h4,
    &h5 {
      @include rem-size(font-size, 20);
      line-height: 1.2;
      font-weight: $font-weight-semibold;
      color: var(--bs-primary);
    }
  }

  .event-date,
  .item-date {
    @include rem-size(font-size, 20);
    font-weight: $font-weight-semibold;
  }

  .card-body {
    .card-text {
      font-family: $font-family-sans-serif;
    }
  }

  //category bottom
  .category-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    font-size: $card-category-size;
    flex-wrap: wrap-reverse;

    .category {
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      color: $card-link-color;
    }

    a {
      color: $card-link-color;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }

    .data {
      text-transform: uppercase;
      color: $text-muted; //$card-p-color;
      font-family: $font-family-monospace;
    }
  }
}

.listing {
  .card-wrapper {
    padding-bottom: 0;
  }
}

//override pastanaga styles for card
//pastanaga style was: .block h1:last-child, .block h2:last-child, .block h3:last-child, .block h4:last-child, .block h5:last-child { margin: calc(2rem - 0.14285714em) 0em 1rem;}
.block .card {
  h1,
  h2,
  h3,
  h4,
  h5 {
    &:last-child {
      margin: initial;
      margin-bottom: var(--bs-card-title-spacer-y);
    }
  }
}
