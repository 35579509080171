@import 'io-sanita-theme/theme/bootstrap-italia-base-config';

body.search-modal-opened {
  overflow: hidden; /*to disable scroll on search modal opened*/
}

#search-modal {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0;

  #search-button {
    border-bottom: 1px solid $neutral-1-a7;
    line-height: 2rem;
  }

  .btn-link {
    &:focus {
      box-shadow:
        $btn-box-shadow,
        0 0 0 $btn-focus-width rgba($link-color, 0.5);
    }
  }

  .modal-header {
    flex-shrink: 0;

    button.btn-close {
      display: none;
    }
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
  }

  .search-filters {
    max-width: 700px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
  }

  .overlay.loading-results {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: hsl(0deg 0% 100% / 64%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    .quick-search {
      h2.h6,
      .quick-search-items .btn.btn-sm {
        font-size: 1.12977rem;
      }
    }
  }
}
