.simpleListTemplate {
  .simple-list-template,
  .simple-list-skeleton-template {
    ul {
      padding: 0;
      line-height: 1rem !important;
      list-style: none;

      &.decoration-pointer {
        padding-left: 1.5rem;
        list-style: inherit;
        list-style-type: disc;
      }

      li {
        margin-bottom: 0.5rem;
        color: var(--bs-link-color);

        a {
          text-decoration: none;
        }
      }
    }
  }
  .simple-list-skeleton-template {
    min-height: 7em;
  }
}
