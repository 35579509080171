.chip,
.public-ui .chip {
  &.chip-accent {
    background-color: transparent;
    border-color: var(--is-accent);
    color: var(--is-accent);
    .icon {
      fill: var(--is-accent);
    }

    & > .chip-label {
      color: var(--is-accent);
    }

    &:hover {
      background-color: var(--is-accent);
      border-color: var(--is-accent);

      & > .chip-label {
        color: var(--is-accent-contrast-text);
      }

      .icon {
        fill: var(--is-accent-contrast-text) !important;
      }
    }
  }
}
