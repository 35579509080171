$tertiary-menu-border: 1px solid #fff;

.tertiary-menu {
  li.nav-item {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      border-right: $tertiary-menu-border;
    }

    a {
      @media (min-width: #{map-get($grid-breakpoints, lg)}) {
        font-size: 0.778em;
      }

      .external-link {
        vertical-align: sub;
        width: 16px;
        height: 16px;
      }
    }
  }

  &:has(+ .is-language-selector) {
    li.nav-item:last-of-type {
      border-right: $tertiary-menu-border;
    }
  }
}
