.btn {
  --bs-btn-hover-color: $primary;
  --bs-btn-active-color: $primary-darker;
}
.btn.btn-link {
  color: var(--bs-link-color); //$link-color;
  .icon {
    fill: var(--bs-link-color); //$link-color
  }
}
.btn-icon {
  .icon {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

button.btn.btn-primary,
.btn.btn-primary {
  svg.icon {
    fill: $primary-text;
  }
  &:focus-visible &:not(:hover) {
    svg.icon {
      fill: var(--bs-btn-hover-color);
    }
  }
}
