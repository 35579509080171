// vjs-control might be better named vjs-button now.
// It's used on both real buttons (play button)
// and div buttons (menu buttons)
.video-js .vjs-control {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 4em;
  @include flex(none);
}

.video-js .vjs-control.vjs-visible-text {
  width: auto;
  padding-left: 1em;
  padding-right: 1em;
}

.vjs-button > .vjs-icon-placeholder:before {
  font-size: 1.8em;
  line-height: 1.67;

  @extend %icon-default;
}

.vjs-button > .vjs-icon-placeholder {
  display: block;
}

.vjs-button > .vjs-svg-icon {
  display: inline-block;
}

// Replacement for focus outline
.video-js .vjs-control:focus:before,
.video-js .vjs-control:hover:before,
.video-js .vjs-control:focus {
  text-shadow: 0em 0em 1em rgba($primary-foreground-color, 1);
}

// Hide control text visually, but have it available for screenreaders
.video-js *:not(.vjs-visible-text) > .vjs-control-text {
  @include hide-visually;
}
