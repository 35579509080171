@import './variables'; //io-sanita-theme variables

// bootstrap italia version variable
// useful to check for the current version
@import 'bootstrap-italia/src/scss/custom/version';

// funzioni e variabili colore
@import 'bootstrap-italia/src/scss/functions';
@import 'bootstrap-italia/src/scss/utilities/colors_vars';

// variables
@import 'bootstrap-italia/src/scss/variables';

$theme-colors: map-merge(
  (
    'accent': $accent,
  ),
  $theme-colors
);

// classi colore
@import 'bootstrap-italia/src/scss/utilities/colors';

// BS5 Configuration
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

@import './variables'; //io-sanita-theme variables - imported twice to override no !default defined variables in bs-italia

@import 'bootstrap/scss/mixins';
// mixins custom
@import 'bootstrap-italia/src/scss/custom/mixins/buttons';
@import 'bootstrap-italia/src/scss/custom/mixins/text-emphasis';
//
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/utilities';
