.it-nav-wrapper {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        a {
          h2,
          h3 {
            color: $header-center-text-color;
          }
        }
      }
      .it-right-zone {
        .it-search-wrapper a.rounded-icon {
          width: 44px;
          height: 44px;
          background-color: #fff;
          border-radius: 50%;

          svg {
            fill: $text-color;
          }
        }
      }
    }
  }
}
