.video-js .vjs-control-bar {
  display: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3.0em;

  @include background-color-with-alpha($primary-background-color, $primary-background-transparency);
}

.video-js.vjs-spatial-navigation-enabled .vjs-control-bar {
  gap: 1px;
}

// Locks the display only if:
//  - controls are not disabled
//  - native controls are not used
//  - there is no error
.video-js:not(.vjs-controls-disabled, .vjs-using-native-controls, .vjs-error) .vjs-control-bar.vjs-lock-showing {
  display: flex !important;
}

// Video has started playing or we are in audioOnlyMode
.vjs-has-started .vjs-control-bar,
.vjs-audio-only-mode .vjs-control-bar {
  @include display-flex;
  visibility: visible;
  opacity: 1;

  $trans: visibility 0.1s, opacity 0.1s; // Var needed because of comma
  @include transition($trans);
}

// Video has started playing AND user is inactive
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  // Remain visible for screen reader and keyboard users
  visibility: visible;
  opacity: 0;
  // prevent a click/tap from interacting with vjs-lock-showing menu's
  // or other controls while we are inactive/hidden
  pointer-events: none;

  $trans: visibility 1.0s, opacity 1.0s;
  @include transition($trans);

}

.vjs-controls-disabled .vjs-control-bar,
.vjs-using-native-controls .vjs-control-bar,
.vjs-error .vjs-control-bar {
  // !important is ok in this context.
  display: none !important;
}

// Don't hide the control bar if it's audio or in audioOnlyMode
.vjs-audio.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar,
.vjs-audio-only-mode.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
