@media (min-width: 992px) {
  .public-ui .navbar .navbar-collapsable .navbar-nav li.nav-item a.nav-link {
    &.active {
      border-color: #fff !important;
    }
  }
}

.public-ui {
  .navbar {
    .navbar-collapsable {
      .navbar-nav {
        li {
          a.nav-link {
            &.focus--mouse {
              border-color: unset !important;
            }
            &.dropdown-toggle {
              svg {
                width: calc(1.5rem - 3px);
                height: calc(1.5rem - 3px);
                @media (max-width: 991px) {
                  fill: $primary;
                }
              }
            }
          }
        }
      }
    }
  }
}
