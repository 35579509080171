.video-js button {
  background: none;
  border: none;
  color: inherit;
  display: inline-block;

  font-size: inherit; // IE in general. WTF.
  line-height: inherit;
  text-transform: none;
  text-decoration: none;
  transition: none;

  // Chrome < 83
  -webkit-appearance: none;
  appearance:         none;
}

// Replacement for focus in case spatial navigation is enabled
.video-js.vjs-spatial-navigation-enabled .vjs-button:focus {
  outline: 0.0625em solid rgba($primary-foreground-color, 1);
  box-shadow: none;
}

.vjs-control .vjs-button {
  width: 100%;
  height: 100%;
}
