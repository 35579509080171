// When the player is "medium" and higher, display everything by default.
//
// When the player is "small", display only:
// - Play button
// - Volume Mute button
// - Progress bar
// - Track buttons
// - Native PiP button
// - Fullscreen button
//
// When the player is "x-small", display only:
// - Play button
// - Volume Mute button
// - Spacer
// - Track buttons
// - Native PiP button
// - Fullscreen button
//
// When the player is "tiny", display only:
// - Play button
// - Volume Mute button
// - Track buttons
// - Native PiP button
// - Fullscreen Button
//
.video-js {

  &.vjs-layout-small,
  &.vjs-layout-x-small,
  &.vjs-layout-tiny {
    .vjs-current-time,
    .vjs-time-divider,
    .vjs-duration,
    .vjs-remaining-time,
    .vjs-playback-rate,
    .vjs-volume-control {
      display: none;
    }

    // Reset the size of the volume panel to the default so we don't see a big
    // empty space to the right of the mute button.
    .vjs-volume-panel.vjs-volume-panel-horizontal {
      &:hover,
      &:active,
      &.vjs-slider-active,
      &.vjs-hover {
        width: auto;
        width: initial;
      }
    }
  }

  // At x-small and tiny, the progress control is too narrow to be useful.
  &.vjs-layout-x-small,
  &.vjs-layout-tiny {

    .vjs-progress-control {
      display: none;
    }
  }

  // At x-small, the buttons alone leave a large gap on the right. Fill it with
  // the spacer element.
  &.vjs-layout-x-small {

    .vjs-custom-control-spacer {
      @include flex(auto);
      display: block;
    }
  }
}
