$arrow-size: $font-size-base * 0.5;

// collapse toggle
/*.accordion-button {
  .collapse-icon::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-top: $arrow-size solid;
    border-right: $arrow-size solid transparent;
    border-bottom: 0;
    border-left: $arrow-size solid transparent;
    vertical-align: $btn-padding-y * 0.5;
  }
  &.collapsed {
    .collapse-icon::before {
      transform: rotate(180deg);
    }
  }
}*/

//collapse wrapper
.accordion {
  border-bottom: 1px solid $gray-border; // UI kit;
  .accordion {
    border: 1px solid $gray-border; // UI kit;
    border-top: 0;
  }

  //background active version
  &.accordion-background-active {
    .accordion-header {
      .accordion-button[aria-expanded='true'] {
        background-color: $primary;
        color: $white;
        border-color: $primary;
        &:before {
          color: $white;
        }
        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='#{url-friendly-color($white)}' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }

  //background hover version
  &.accordion-background-hover {
    .accordion-header {
      .accordion-button:hover {
        background-color: $primary;
        color: $white;
        border-color: $primary;
        &:before {
          color: $white;
        }
        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='#{url-friendly-color($white)}' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }

  //left icon version
  &.accordion-left-icon {
    .accordion-header {
      .accordion-button {
        &:after {
          content: none;
        }
        &:before {
          font-weight: 300;
          content: '-';
          float: left;
          margin: 0 1rem 0.3333333333333333rem 0;
          width: 1.5rem;
          font-size: 1.5rem;
          line-height: 1.2rem;
          font-family: $font-family-sans-serif;
          transform: none;
          overflow: hidden;
        }
        &[aria-expanded='false'] {
          &:before {
            content: '+';
          }
        }
      }
    }
  }
}

//item
.accordion-item {
  border: none;
  &:first-of-type,
  &:last-of-type {
    border-radius: 0;
    .accordion-button {
      border-radius: 0;
    }
  }
}

//header
.accordion-header {
  position: relative;

  .accordion-button {
    width: 100%;
    text-align: left;
    border: 0;
    background-color: transparent;
    border-top: 1px solid $gray-border; // UI kit;
    box-shadow: none;
    padding: 14px 24px;
    cursor: pointer;
    color: $primary;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
    }
    &:not(.collapsed) {
      color: $gray-secondary;
      &:after {
        transform: scaleY(1);
      }
    }
    &:not(.collapsed):hover:before,
    &[aria-expanded='true']:hover:before {
      text-decoration: none;
    }
    &:after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 24 24' %3E%3Cg%3E%3Cpath fill='#{url-friendly-color($primary)}' d='M12,10.3l4.8,4.8c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1c0,0,0,0,0,0l-4.8-4.8c-0.6-0.6-1.5-0.6-2.1,0L6.2,14c-0.3,0.3-0.3,0.8,0,1c0,0,0,0,0,0c0.3,0.3,0.8,0.3,1.1,0L12,10.3z'/%3E%3C/g%3E%3C/svg%3E");
      float: right;
      width: 1.5rem;
      height: auto;
      line-height: 0.1rem;
      color: $primary; // UI kit
      transition: transform 0.3s;
      background-image: none;
      transform: scaleY(-1);
    }
    &:hover {
      background: none;
      text-decoration: underline;
      &:after {
        text-decoration: none;
      }
    }
    &:active,
    &:hover,
    &:focus {
      border-top-color: $gray-border; // UI kit !important;
    }
  }
}

// body
.accordion-body {
  padding: 12px 24px 42px;
  font-size: 1rem;
  line-height: 1.5rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .accordion-header button[aria-expanded='true']:before {
    height: 0;
    width: 0;
  }
}
