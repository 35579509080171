.card.card-module {
  .download-formats {
    a {
      display: flex;
      justify-content: space-between;

      .icon.external-link {
        display: none;
      }
    }
  }
}
