@import 'addonsThemeCustomizationsVariables';

@import './bootstrap-italia';
@import 'cms-ui';

/*IoSanita theme*/
@import './css_variables';
@import './io-sanita/mixins';
@import './io-sanita/main';
@import './io-sanita/home';
@import './io-sanita/buttons';
@import './io-sanita/chip';
@import './io-sanita/point-list';
@import './io-sanita/tables';
@import './io-sanita/login';
@import './io-sanita/addons/volto-gdpr-privacy';
@import './io-sanita/addons/volto-venue';
@import './io-sanita/addons/volto-data-grid-widget';
@import './io-sanita/addons/volto-form-block';
@import './io-sanita/components/layout/navbar';
@import './io-sanita/components/layout/mobileMenu';
@import './io-sanita/components/layout/tertiaryMenu';
@import './io-sanita/components/layout/footer';
@import './io-sanita/components/layout/pageLoader';
@import './io-sanita/components/layout/stickyHeader';
@import './io-sanita/components/cards';
@import './io-sanita/components/mapPinDirections';
@import './io-sanita/components/view/commons/pageHeader';

@import './io-sanita/components/blocks/common';
@import './io-sanita/components/blocks/skeleton';
@import './io-sanita/components/blocks/listing';
@import './io-sanita/components/blocks/grid';
@import './io-sanita/components/blocks/search';
@import './io-sanita/components/blocks/video';

@import './io-sanita/subsites/_common';
/****************/

@import 'addonsThemeCustomizationsMain';
