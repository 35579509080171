//mobile

//basic animation
@keyframes spinnerAnim {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
//double spinner anim 1
@keyframes spinnerAnimInner1 {
  0% {
    transform: rotate(60deg);
  }
  100% {
    transform: rotate(205deg);
  }
}
//double spinner anim 2
@keyframes spinnerAnimInner2 {
  0% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(-105deg);
  }
}

//standard spinner
.progress-spinner {
  display: block;
  width: $v-gap * 6;
  height: $v-gap * 6;
  border-radius: 50%;
  border: 4px solid $color-background-secondary-lighter; // UI Kit
  //spinner active state
  &.progress-spinner-active {
    animation: spinnerAnim 0.75s linear infinite;
  }
  //standard spinner active state
  &.progress-spinner-active:not(.progress-spinner-double) {
    border-color: $secondary;
    border-bottom-color: $color-background-secondary-lighter;
  }
  //small size
  &.size-sm {
    width: $v-gap * 4;
    height: $v-gap * 4;
  }

  //large size
  &.size-lg {
    width: $v-gap * 8;
    height: $v-gap * 8;
  }

  //extra-large size
  &.size-xl {
    width: $v-gap * 10;
    height: $v-gap * 10;
  }

  //double spinner
  &.progress-spinner-double {
    $size: 48px;
    display: inline-block;

    //small size
    &.size-sm {
      width: $v-gap * 4;
      height: $v-gap * 4;
      //double spinner inner
      .progress-spinner-inner {
        width: $v-gap * 4;
        height: $v-gap * 2;
      }
    }

    &.size-lg {
      width: $v-gap * 8;
      height: $v-gap * 8;
      //double spinner inner
      .progress-spinner-inner {
        width: $v-gap * 8;
        height: $v-gap * 4;
      }
    }

    &.size-xl {
      width: $v-gap * 10;
      height: $v-gap * 10;
      //double spinner inner
      .progress-spinner-inner {
        width: $v-gap * 10;
        height: $v-gap * 5;
      }
    }

    //double spinner active state
    &.progress-spinner-active {
      .progress-spinner-inner {
        opacity: 1;
      }
    }
    //double spinner inner
    .progress-spinner-inner {
      width: $v-gap * 6;
      height: $v-gap * 3;
      overflow: hidden;
      margin-left: -$v-gap * 0.5;
      opacity: 0;
      transition: opacity 0.3s;
      &:nth-child(1) {
        margin-top: -$v-gap * 0.5;
      }
      &:nth-child(2) {
        transform: rotate(180deg);
        &:after {
          animation-name: spinnerAnimInner2;
        }
      }
      &:after {
        content: '';
        display: inline-block;
        transform: rotate(45deg);
        border-radius: 50%;
        border: 4px solid $secondary;
        border-right: 4px solid transparent;
        border-bottom: 4px solid transparent;
        width: 100%;
        height: 200%;
        animation: spinnerAnimInner1 0.75s cubic-bezier(0.25, 0.1, 0.5, 1) infinite alternate;
      }
    }
  }
}
