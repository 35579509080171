@import 'io-sanita-theme/theme/io-sanita/mixins';
.public-ui .card.card-place {
  .avatar {
    border-radius: 0;
    background-color: var(--primary-lightest);
    color: var(--bs-primary);

    &.size-xl {
      min-width: 80px;
    }
    &.size-l {
      min-width: 32px;
    }
  }

  .card-body {
    .card-place-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .card-text {
      &,
      p {
        font-size: 0.8886rem;
      }
    }
  }

  .card-footer {
    a {
      display: inline-flex;
      align-items: center;

      .icon.icon-xs {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.card-place-small {
    font-size: 0.889rem;

    h3,
    h4,
    h5 {
      &.card-title {
        @include rem-size(font-size, 18);
      }
    }

    .category {
      font-size: 0.8rem;
    }
  }
}
