.block.form {
  .single-block.subblock-draggable .ui.basic.button.delete-button,
  .volto-subblocks-wrapper
    .single-block.subblock-draggable
    .ui.basic.button.delete-button,
  .form-field .single-block.subblock-draggable .ui.basic.button.delete-button {
    top: 0.7rem !important;
    right: -0.15rem !important;
    margin-right: -13px !important;
    border: 1px solid #c7d5d7 !important;
    z-index: 1;
  }

  .single-block .dragsubblock,
  .block.form .volto-subblocks-wrapper .single-block .dragsubblock,
  .block.form .form-field .single-block .dragsubblock {
    top: 0.75rem;
    left: -0.75rem;
  }
}
