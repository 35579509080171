.card {
  a.card-title-link:not(:hover):not(:active):not(:focus) {
    text-decoration: none;
  }

  &.natural-image-size {
    .img-responsive {
      position: static;
      height: auto;
      padding: 0;

      .img-wrapper {
        position: static;
        margin: 0;
      }
    }
  }
}
