/* Blocco faccette */

.block.search {
  .full-width {
    height: 100% !important;
  }

  .search-wrapper {
    position: relative;
    margin-top: 1.75rem;
    background-color: #fff;

    .form-group {
      flex: 1;
      margin: 0;

      .form-text {
        display: none;
      }
    }

    .input-group-append,
    .clear-icon,
    input {
      height: 45px;
    }

    label {
      color: var(--bs-gray-secondary);
    }

    label.inactive,
    input {
      padding-left: 2.5rem !important;
    }

    label.inactive {
      color: transparent;
    }

    label.active {
      top: -8px;
      font-size: 1rem;
    }

    .autocomplete-icon {
      position: absolute;
      z-index: 5;
      top: 7px;
      right: auto;
      left: 0;

      .icon {
        fill: $link-color;
      }
    }

    .clear-icon {
      position: absolute;
      z-index: 5;
      right: 80px;
      border: none;
      border-bottom: 1px solid hsl(210deg, 17.6470588235%, 43.35%);
      font-size: 1rem;
      outline: none;
    }
  }

  .search-details {
    color: var(--bs-gray-900);
  }

  .search-results .public-ui .template-wrapper {
    .container {
      max-width: 100%;
      padding: 0 !important;
    }
  }

  .sideColumn {
    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
      padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }

    .columnTextTitle {
      color: var(--bs-gray-900);
      text-transform: uppercase;
    }

    .facets {
      .facet .columnTextTitle {
        text-transform: none;
      }

      .daterange-facet {
        .DateRangePickerInput {
          border-bottom: 1px solid hsl(210deg, 17.6470588235%, 43.35%);

          input[type='text'] {
            border-bottom: none;
          }
        }
      }

      .select-facet {
        // Same div, but for some reason when built, valuecontainer
        // class is not present in html, design-react-kit fault
        div[class*='-ValueContainer'],
        div[class*='-control'] > div:first-child {
          position: relative;
          display: flex;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          overflow: hidden;
          height: 2.5rem;
          box-sizing: border-box;
          -webkit-flex: 1 1 0%;
          -ms-flex: 1 1 0%;
          flex: 1 1 0%;
          flex-wrap: nowrap;
          -webkit-align-items: center;
          align-items: center;
          padding: 2px 8px;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-overflow-scrolling: touch;
        }

        div[class*='-placeholder'] {
          color: #484848;
        }
      }
    }
  }

  .search-results-count-sort {
    .accordion-wrapper {
      position: relative;
      min-width: 350px;
      @media (max-width: #{map-get($grid-breakpoints, md)}) {
        min-width: 100%;
      }

      .accordion-header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        border: none;
        border-color: hsl(210deg, 17.6470588235%, 43.35%) !important;
        border-bottom: 1px solid;
        font-size: 1rem;
        transition:
          border-color 0.3s ease-in,
          background-color 0.2s cubic-bezier(0, 1, 0, 1) 0.2s;

        &[aria-expanded='true'] {
          border-color: transparent !important;
          box-shadow: 0 1rem 2rem -0.25rem rgba(0, 0, 0, 0.15) !important;
          transition:
            background-color 0.2s cubic-bezier(0, 1, 0, 1),
            border-color 0.05s cubic-bezier(1, 0, 1, 0);
        }

        .filter-list-title {
          display: flex;

          .accordion-control {
            position: relative;
            margin-right: 28px;

            .indicator {
              position: absolute;
              z-index: 10;
              right: -22px;
              bottom: -5px;
            }
          }
        }
      }

      .accordion-content {
        position: absolute;
        z-index: 10;
        right: 0;
        left: 0;
        overflow: hidden;
        max-height: 0;
        padding: 0;
        transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);

        &[aria-expanded='true'] {
          overflow: auto;
          max-height: 1000vh;
          box-shadow: 0 0.8rem 1rem -0.25rem rgba(0, 0, 0, 0.15) !important;
          transition: max-height 0.25s cubic-bezier(1, 0, 1, 0);
        }

        .accordion-inner {
          padding: 0.75rem 1rem;

          .filter-list-group {
            display: flex;
            flex-direction: column;

            .label-title {
              color: var(--bs-gray-secondary);
              font-size: 0.875rem;
              font-weight: 700;
              text-transform: uppercase;
            }

            .form-label {
              align-items: center;
              justify-content: space-between;
            }
          }
        }

        .link-more {
          text-align: right;

          a {
            font-weight: bold;

            svg {
              width: 1.2em;
              fill: $link-color;
            }
          }
        }

        .clear-filters {
          width: 100%;
          align-items: center;
          justify-content: center;
          margin-top: 1rem;
          font-size: 17px;

          svg {
            margin-right: 0.5rem !important;
            fill: var(--bs-danger);
          }
        }
      }
    }
  }
  .block.listing {
    .bandi-template .bandi-template-cards-wrapper {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      @media (min-width: #{map-get($grid-breakpoints, md)}) and (max-width: #{map-get($grid-breakpoints, lg)}) {
        display: block !important;
      }
    }
  }
}

/*Blocchi di ricerca con la barra dei filtri (tipo SearchBandi)*/
$search-input-color: #484848;
.search-block-filters {
  .search-container {
    width: 100%;
    padding: 0.5rem 1rem;
    gap: 0.5rem;

    .filter-wrapper {
      flex: 1;

      &.text-filter {
        min-width: 35%;
        input {
          width: 100%;
          color: $search-input-color;
          font-weight: 300;
        }
      }

      &.select-filter {
        min-width: 150px;

        &:focus-within {
          box-shadow: 0 0 0 2px $focus-outline-color;
        }

        .react-select__control {
          //border: none !important;
          // background-color: transparent;

          .react-select__placeholder {
            // color: #fff !important;
          }

          .react-select__indicators {
            .clearButton {
              .icon {
                fill: var(--bs-primary);
              }
            }
            svg {
              //fill: #fff;
            }
            .react-select__indicator {
              padding: 5px 8px;
            }
          }

          .react-select__single-value {
            // color: #fff !important;
            font-weight: 300;
            color: $search-input-color !important;
          }
        }
      }

      &.date-filter {
        .DateRangePickerInput {
          //background-color: transparent;
          border-bottom: 1px solid;

          .DateInput {
            width: 116px;
            //   background-color: transparent;
          }

          .DateInput_input {
            border: none;
            //    background-color: transparent;
            // color: #fff;
            font-weight: 300;

            &::placeholder {
              //  color: #fff;
            }

            &.DateInput_input__focused {
              box-shadow: 0 0 0 2px $focus-outline-color;
            }
          }

          .DateRangePickerInput_arrow {
            //  color: #fff;

            .icon {
              //      fill: #fff;
            }
          }

          .DateRangePickerInput_clearDates {
            opacity: 0.8;
            transition: opacity 0.1s ease;

            svg {
              fill: var(--bs-primary);
            }

            &:hover,
            &:active {
              background: none;
              opacity: 1;
            }

            &:focus {
              opacity: 1;
              outline: none;

              svg {
                //fill: $accent;
              }
            }
          }
        }
      }

      @media (max-width: #{map-get($grid-breakpoints, lg)}) {
        flex: 1 1 100%;
        margin-right: 0 !important;
      }
    }

    @media (max-width: #{map-get($grid-breakpoints, lg)}) {
      padding: 1rem;
    }
  }
}
