@import 'io-sanita-theme/theme/io-sanita/mixins';

.public-ui .card.card-file {
  .card-body {
    h3,
    h4,
    h5 {
      &.card-title {
        @include rem-size(font-size, 18);
      }
    }
    .icon {
      height: 40px !important;
      width: auto;
      min-width: 40px;
      font-size: 40px;
    }
    .card-text {
      &,
      p {
        font-size: 1rem;
      }
    }

    .card-file-content {
      &.Link {
        .card-title a .icon {
          display: none;
        }
      }
    }
  }
}
