.it-footer {
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  .it-brand-text {
    color: $footer-main-text-color;
  }

  .icon {
    fill: $footer-main-text-color;
    color: $footer-main-text-color;
  }
  .it-footer-main {
    .it-brand-wrapper {
      //icon externalLink
      a svg.external-link {
        width: 1rem;
        height: 1rem;
      }
      .it-brand-text {
        .it-brand-title {
          @include rem-size(font-size, 24);
          font-weight: $font-weight-semibold;
        }
        .it-brand-tagline {
          font-size: 0.875rem;
        }
      }
    }
  }
}
