.vjs-poster {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
}

// Hide the poster after the video has started playing and when native controls are used
.vjs-has-started .vjs-poster,
.vjs-using-native-controls .vjs-poster {
  display: none;
}

// Don't hide the poster if we're playing audio or when audio-poster-mode is true
.vjs-audio.vjs-has-started .vjs-poster, 
.vjs-has-started.vjs-audio-poster-mode  .vjs-poster,
.vjs-pip-container.vjs-has-started .vjs-poster {
  display: block;
}

.vjs-poster img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
