//mobile
.it-header-navbar-wrapper {
  nav {
    background: transparent;
  }
}

//Tablet horizontal / small desktop
@include media-breakpoint-up(lg) {
  .it-header-navbar-wrapper {
    background: $header-center-bg-color;
    .nav-item.megamenu > a.dropdown-toggle:before,
    .nav-item.megamenu > button.dropdown-toggle:before {
      bottom: -$header-nav-icon-arrow-megamenu-distance;
    }
    nav {
      a {
        text-decoration: none;
      }
      .navbar-collapsable {
        .menu-wrapper {
          background: transparent;

          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .navbar-nav {
            padding: 0;

            &.navbar-secondary {
              display: flex;
              justify-content: flex-end;

              a {
                font-size: 0.875rem;
                line-height: 1.6;
              }
            }
          }
        }
      }
    }
  }
}
