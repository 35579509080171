$primary-h: 190 !default;
$primary-s: 95 !default;
$primary-b: 55 !default;
$primary: hsl(
  $primary-h,
  $primary-s * 1%,
  $primary-b * 0.5%
) !default; //#07768d;
$primary-dark: hsl($primary-h, $primary-s * 1%, 20%) !default;
$primary-darker: hsl(
  $primary-h,
  $primary-s * 1%,
  12%
) !default; // color-ocean-10 --> $primary-a12 --> primary-darker
$accent: #9c0769 !default;

$info: hsl(189, 47%, 54%) !default;
$light: hsl(189, 28%, 88%) !default;
$primary-text: #fff !default;
$accent-contrast-text: #fff !default;
$bg-primary-lightest: hsl($primary-h, 50%, 97%) !default; //#F4FAFB;
$bg-primary-lighter: hsl($primary-h, 50%, 87%) !default; //#CDE9EE;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'accent': $accent,
  ),
  $theme-colors
) !default;

//font
$font-weight-base: 300 !default;
$body-color: #2f475e !default;
$headings-color: #1a1a1a !default;
$accordion-title-color: $body-color !default;

//header
$header-slim-bg-color: $primary-dark !default;
$header-center-text-color: $primary-text !default;
$navigation-toggle-button-icon-color: $primary-text !default;

//footer
$footer-top-background: $primary-darker !default;
$footer-bottom-background: $footer-top-background !default;
$footer-main-text-color: $header-center-text-color !default;
$footer-font-size: 0.889rem !default;
$footer-borders: 1px solid hsla($primary-h, 70.59%, 20%) !default;

//nav
$nav-link-font-size: 1rem !default; // 18px
$nav-link-font-weight: 600 !default;

//breadcrumbs
$breadcrumb-link-color: $primary-darker !default;

//dropdown
$dropdown-font-size: 0.889rem !default; // 16px

//card
$card-link-color: $accent !default;
$card-color: $body-color !default;
$card-p-color: $body-color !default;
$card-category-size: 0.875rem !default; // 15.75px

//chips
$color-text-secondary: $accent !default;
$color-border-subtle: $accent !default;

//badge
$badge-padding-x: 1rem;
$badge-font-size: 0.778rem !default; // 14px

//search
$highlight-search: #ff0;

//focus
$focus-outline-color: #000;
$focus-inner-color: #fff;
//$color-text-base: #2f475e !default;;

// ALERT-BLOCK CUSTOM COLORS
$alert-danger: #a32219 !default;
$alert-danger-text: #fff !default;
$alert-warning: #eb973f !default;
$alert-warning-text: #000 !default;
$alert-info: #f0c250 !default;
$alert-info-text: #000 !default;

// GDPR-BANNER RELATED
$gdpr-accept-all: #005700 !default;
$gdpr-toggle-checked: #005700 !default;
$gdpr-toggle: #b22515 !default;
$gdpr-toggle-border: #000 !default;
$gdpr-focus-color: #ff9800 !default;
$gdpr-link-color: #076173 !default;
$gdpr-focus-shadow:
  inset 0 1px 0 rgba(255, 255, 255, 0.15),
  0 1px 1px rgba(0, 0, 0, 0.075),
  0 0 0 0.2rem $gdpr-focus-color !default;
