.iosanita-page-loader {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;

  .progress {
    min-height: 4px;
  }
}
