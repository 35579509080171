@import 'io-sanita-theme/theme/bootstrap-italia-base-config';

.subsite-header {
  position: relative;
  overflow: hidden;
  background-color: var(--bs-primary);
  background-size: cover;

  .text {
    z-index: 2;
    width: 100%;
    color: var(--bs-primary-text);
    font-size: 1.5em;
    font-style: italic;
    line-height: 1.5em;
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    a {
      color: var(--bs-primary-text);
      font-family: var(--bs-font-sans-serif);
      font-style: normal;
    }
    a:not(.inline-link) {
      &:hover,
      &:active,
      &:focus {
        color: var(--bs-primary-text);
      }
    }

    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      .container {
        padding: 2em 8em;
      }
    }

    p {
      margin: 0;
    }

    h3 {
      margin-bottom: 0.5em;
      font-size: 1.7em;
    }

    a {
      //   @extend .btn;
      //   @extend .btn-primary;
      font-size: 1rem;
    }

    &.subsite-internal-page {
      text-align: left;

      @media (min-width: #{map-get($grid-breakpoints, md)}) {
        .container {
          padding: 2em 0em;
        }
      }
    }
  }
}
