.vjs-menu-button-popup .vjs-menu {
  display: none;
  position: absolute;
  bottom: 0;
  width: 10em;
  left: -3em; // (Width of vjs-menu - width of button) / 2
  height: 0em;
  margin-bottom: 1.5em;
  border-top-color: rgba($primary-background-color, $primary-background-transparency); // Same as ul background
}

.vjs-pip-window .vjs-menu-button-popup .vjs-menu {
  left: unset;
  right: 1em; // Extra offset for last menu button in pip window, as fullscreen button not present
}

// Button Pop-up Menu
.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  @include background-color-with-alpha($primary-background-color, $primary-background-transparency);

  position: absolute;
  width: 100%;
  bottom: 1.5em; // Same bottom as vjs-menu border-top
  max-height: 15em;
}

.vjs-layout-tiny .vjs-menu-button-popup .vjs-menu .vjs-menu-content,
.vjs-layout-x-small .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height: 5em;
}

.vjs-layout-small .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height: 10em;
}

.vjs-layout-medium .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height: 14em;
}

.vjs-layout-large .vjs-menu-button-popup .vjs-menu .vjs-menu-content,
.vjs-layout-x-large .vjs-menu-button-popup .vjs-menu .vjs-menu-content,
.vjs-layout-huge .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height: 25em;
}

.vjs-workinghover .vjs-menu-button-popup.vjs-hover .vjs-menu,
.vjs-menu-button-popup .vjs-menu.vjs-lock-showing {
  display: block;
}
