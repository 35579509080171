// fix footer bg, text colors and columns line

//generali e in comune tra footer-main e footer-small
.it-footer-main,
.public-ui .it-footer-main,
.it-footer-small-prints,
.public-ui .it-footer-small-prints {
  &,
  p,
  ul {
    font-size: $footer-font-size;
  }

  a {
    color: $white;

    &:hover:not(.disabled) {
      text-decoration: underline;
    }
  }
}

//footer main
.it-footer-main,
.public-ui .it-footer-main {
  //brand
  .it-brand-wrapper {
    a .icon {
      margin-right: 1rem;
    }
  }
  .row {
    //bordo colonne
    .col-md-4 {
      border-bottom: var(--is-footer-borders); // $footer-borders;

      &:last-of-type {
        border-bottom: none;
      }
      @media (min-width: #{map-get($grid-breakpoints, md)}) {
        &.last-row-cols {
          border-bottom: none;
        }
      }
    }

    //titoli h4
    > div {
      h4:empty:before {
        //on edit
        content: '\200b';
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          content: none;
        }
      }

      h4 {
        padding-bottom: $footer-font-size;
        margin-bottom: $footer-font-size;
        //border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
        font-size: 1rem;
        @media (max-width: #{map-get($grid-breakpoints, md)}) {
          &:empty {
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  //ul
  ul {
    padding: 0;
    list-style-type: none;

    li {
      padding: 0.25rem 0;
    }
  }
  .link-list-wrapper {
    ul {
      li {
        a {
          text-decoration: underline;
        }
      }
    }
  }

  //social
  .social {
    .text-white {
      .icon-white {
        fill: currentcolor !important;
      }

      .icon {
        fill: currentcolor !important;
      }
    }

    .list-inline-item {
      margin-bottom: 1em;

      &,
      &:not(:last-child) {
        margin-right: 0;
      }
      a:first-of-type {
        padding-left: 0 !important;
      }
    }
  }
}

//footer small
.it-footer-small-prints,
.public-ui .it-footer-small-prints {
  border-top: var(--is-footer-borders); // $footer-borders;
  a,
  button {
    word-break: normal;
    font-size: $footer-font-size !important;
  }

  a,
  button,
  a:hover:not(.disabled),
  button:hover:not(.disabled) {
    text-decoration: underline;
  }

  .in-evidence {
    font-weight: bold;
  }

  button {
    padding: 0;
    border: none;
    margin: 0;
    background: none;
    color: $white;

    &:focus {
      outline: none;
    }
  }
}

//fix footer styles for volto-editablefooter footerTop field
.it-footer .it-footer-main .it-brand-wrapper .footerTop,
.footer-configuration-widget .footer-top-segment .block.gridBlock .grid-items {
  h2 {
    margin-bottom: 0;
    font-size: 1.777778rem;
    font-weight: 600;
    letter-spacing: unset;
    line-height: 1.1;
  }

  h3 {
    font-weight: 600;
  }

  .block.image img {
    width: auto;
    max-height: 75px;
  }

  .block.gridBlock {
    .col {
      &:has(.block.image) {
        width: auto;
        flex: 0 0 auto;
      }
    }
  }
}
