// text-color
// excluding `secondary` due to different colors used for background and text, using a direct class instead
@each $color, $value in $theme-colors {
  @if $color != 'white' and $color != 'secondary' {
    .text-#{$color} {
      color: $value;
    }
  }
}

.text-secondary {
  color: $color-text-secondary;
}
