/*
* Bottone colore di accento (btn-accent)
*/

.public-ui {
  .btn.btn-accent {
    &,
    &:hover,
    &:active,
    :focus {
      svg {
        fill: $accent-contrast-text;
      }
    }
  }

  a.btn.btn-accent {
    &:focus-visible,
    &:focus {
      color: $accent;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: $focus-outline-color;
      box-shadow:
        inset 0 1px 0 $focus-outline-color,
        0 1px 1px $focus-outline-color,
        0 0 0 0.2rem $focus-outline-color;
      outline: none;
    }
  }

  .btn.btn-link-accent {
    color: $accent;

    &.no-padding {
      padding: 0;
    }

    svg {
      fill: $accent;
    }
  }
}
