//mobile
.tooltip {
  border-radius: $border-radius;
  font-size: 0.75rem;

  //hide arrow
  .tooltip-arrow {
    display: none;
  }
  //text
  .tooltip-inner {
    padding: 7px $v-gap * 2;
    background-color: $color-background-emphasis;
    @include media-breakpoint-up(md) {
      .tooltip-inner {
        padding: 4.5px $v-gap;
      }
    }
    a {
      color: $white;
      text-decoration: underline;
    }
  }
  //padding
  &.bs-tooltip-top,
  &.bs-tooltip-bottom {
    padding: $v-gap 0;
  }
  &.bs-tooltip-left,
  &.bs-tooltip-right {
    padding: 0 $v-gap;
  }
}
