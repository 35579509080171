.form-feedback {
  margin-left: 0.5rem;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  &.just-validate-error-label {
    color: #d9364f;
  }
}

.input-group-text:has(~ .focus--mouse:not(.btn)),
.focus--mouse:not(.btn) ~ .input-group-text,
button:has(~ .focus--mouse:not(.btn)),
.focus--mouse:not(.btn) + button {
  border-color: inherit !important;
  box-shadow: none !important;
  outline: none !important;
}

//same border color for element in input-group
.input-group-text:has(~ .is-invalid),
.is-invalid ~ .input-group-text,
button:has(~ .is-invalid),
.is-invalid + button {
  border-color: #d9364f;
}

.sr-only-justvalidate-bi {
  display: none;
}
.just-validate-success-field {
  border-color: #008758 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300cc85' viewBox='0 0 192 512'%3E%3Cpath d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
}

.input-group-text:has(~ .just-validate-success-field),
.just-validate-success-field ~ .input-group-text,
button:has(~ .just-validate-success-field),
.just-validate-success-field + button {
  border-color: #008758;
}

//move buttons to make validation icon visible
.just-validate-success-field + .input-group-text.align-buttons,
.is-invalid + .input-group-text.align-buttons {
  right: 30px;
}

.is-invalid + .input-group-text.align-buttons {
  bottom: 22px; //height of the form-feedback element
}

.autocomplete__wrapper {
  .form-feedback.just-validate-error-label {
    position: absolute;
  }
}

textarea {
  &.form-control {
    background-position: top 0.3em right 0.3em !important;
    background-size: 37px 30% !important;
  }
  &.is-invalid {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  &.just-validate-success-field {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
}

input[type='checkbox'],
input[type='radio'] {
  &.just-validate-success-field {
    + label {
      color: #008758;
    }
  }
}

select {
  &.is-invalid {
    border-bottom: 1px solid #d9364f;
  }
  &.just-validate-success-field {
    border-bottom: 1px solid #008758;
  }
}
