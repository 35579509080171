@import 'io-sanita-theme/theme/bootstrap-italia-base-config';

.page-side-menu {
  .menu-wrapper {
    width: 100%;

    .accordion-wrapper {
      .accordion {
        border-bottom: none;

        .accordion-header {
          display: block;
          width: 100%;

          button.accordion-button {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            border: none;
            background: transparent;

            h2 {
              padding: 0px;
              border: none;
              margin: 0;
              text-transform: none;
              color: $accordion-title-color;
              font-size: 1rem;
              font-weight: $font-weight-semibold;
              line-height: 2;

              &:after {
                content: none;
              }
            }
          }
        }

        .accordion-body {
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: #{map-get($grid-breakpoints, lg)}) {
  .page-side-menu {
    .menu-wrapper {
      .accordion-wrapper {
        .accordion {
          .accordion-header {
            button.accordion-button {
              .accordion-title {
                padding-top: 1.3em;
                padding-bottom: 1.3em;
              }
            }
          }
        }
      }
    }

    .navbar.it-navscroll-wrapper {
      .menu-wrapper {
        padding: 0;
      }
    }
  }

  .it-page-sections-container {
    border-top: none !important;
  }
}
