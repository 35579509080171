@import 'io-sanita-theme/theme/bootstrap-italia-base-config';
@import 'io-sanita-theme/theme/io-sanita/mixins';

.card-featured.card-teaser-wrapper-equal.card-teaser-block-2 {
  display: block;

  h3,
  h4,
  h5 {
    &.card-title {
      @include rem-size(font-size, 24);
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-body-main {
      //wrapper per mantenere il contenuto della card all'inizio della card
    }

    &.no-image {
      flex: 0 0 100% !important;
    }
  }

  .card-teaser-image {
    .card-image {
      overflow: hidden;
      position: relative;
      img,
      > .img-wrapper {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &.card-featured-large {
    .card-teaser-image {
      .card-body {
        flex: 0 0 49%; //default large
      }
      .card-image {
        flex: 0 0 49%; //default large
        overflow: hidden;
      }
    }
  }

  &.card-featured-small {
    .card-teaser-image {
      .card-body {
        flex: 0 0 69%;
      }
      .card-image {
        flex: 0 0 30%;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &.card-featured-large {
      .card.card-flex {
        flex-direction: column-reverse;
        .card-image {
          flex: unset;
          aspect-ratio: 21/11;
        }
      }
    }
    &.card-featured-small {
      .card-teaser-image {
        .card-body {
          flex: 0 0 59%;
        }
        .card-image {
          flex: 0 0 40%;
        }
      }
    }
  }
}
