.public-ui .card.card-persona {
  .avatar {
    background-color: var(--primary-lightest);

    &.size-xl {
      min-width: 80px;
    }
    &.size-l {
      min-width: 32px;
    }
  }

  .card-body {
    .card-text {
      &,
      p {
        font-size: 0.8886rem;
      }
    }
  }
}
