.siteroot,
body.public-ui.contenttype-lr:not(.section-search) {
  .block-editor-title,
  .documentFirstHeading,
  #briciole {
    display: none;
  }
}

body.subsite.subsite-root:not(.section-search) {
  &,
  .public-ui {
    .documentFirstHeading,
    #briciole {
      display: none;
    }
  }
}
